import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import CategoryTile from '~components/CategoryTile'
import FeatureTitle from '~components/FeatureTitle'
import Section from '~components/Section'
import { useState, useEffect } from 'react'

const FeaturedCategories = ({ className, categories, cols, title }) => {

	const [small, setSmall] = useState(false)

	useEffect(() => {
		if (categories?.length > 4) {
			setSmall(true)
		}
	}, [categories])

	return (
		<Wrap className={className} cols={cols}>
			<Title title={title}/>
			<Grid small={small}>
				{categories.map((category, index) => (
					<Category key={category?._key} category={category} small={small}/>
				))}
			</Grid>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin: 82px 0;
`

const Title = styled(FeatureTitle)`
	grid-column: span 12;
	margin-bottom: 41px;
	${mobile}{
		margin-bottom: 25px;
	}
`

const Grid = styled.div`
	grid-column: span 12;
	display: flex;
	${mobile}{
		display: grid;
		grid-template-columns: ${props => props.small ? `repeat(4, 1fr)` : `repeat(2, 1fr)`};
		grid-column-gap: 15px;
		grid-row-gap: 20px;
	}
`
const Category = styled(CategoryTile)`
	flex: 1;
	${mobile}{
		flex: 50%;
	}
`

FeaturedCategories.propTypes = {
	className: PropTypes.string
}

export default FeaturedCategories