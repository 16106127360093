import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useEmblaCarousel from 'embla-carousel-react'
import { useState } from 'react'
import FeatureTitle from '~components/FeatureTitle'
import { Larr, Rarr, Heart } from '~components/Svg'
import BrandTile from '~components/BrandTile'
import ProductTile from '~components/ProductTile'
import Section from '~components/Section'

const CenteredSliderEmbla = ({ className, heart, productSlider, brandsSlider, slides, title, children }) => {

	const [emblaRef, emblaApi] = useEmblaCarousel({
		containScroll: 'trimSnaps',
		autoplay: false,
		loop: true,
		align: 0,
	})

	
	const prevSlide = () => emblaApi.scrollPrev()
	const nextSlide = () => emblaApi.scrollNext()

	useEffect(() => {
		if (emblaApi){
			const autoSlide = setInterval(() => {
				emblaApi.scrollNext()
			}, 4000)
			return () => clearInterval(autoSlide)
		}
	}, [emblaApi])

	return (
		<Wrap className={className}>
			  {productSlider &&
          <MobileHeader> 
						{(title) &&
							<>
							{title && 
									<FeatureHeading>
										<FeatureTitle title={title}/>
										{heart && <CrushHeart />}
									</FeatureHeading>
								} 
							</>
						}
              <Arrows>
                <button onClick={() => prevSlide()} css={css`margin-right: 10px;`}>
                  <Larr css={css`height: 8px;`}/>
                </button>
                <button onClick={() => nextSlide()}>
                  <Rarr css={css`height: 8px;`}/>
                </button>
              </Arrows>
          </MobileHeader>
        }
				{(brandsSlider || title) &&
					<Header css={css`${mobile}{display: ${(title) ? `none` : `flex`}};}`}>
						{brandsSlider && 
							<h4>Brands mentioned in this article</h4>
						}
						{title && 
							<>
								<FeatureTitle title={title} />
								{heart && <CrushHeart />}
							</>
						}
					</Header>
				}
				<SliderButton 
          onClick={() => prevSlide()}
          css={css`justify-self: end; display: ${slides?.length <= 4 ? `none` : `block`};`}
        >
          <Larr/>
        </SliderButton>
				<SliderRef ref={emblaRef}>
					<SliderContainer>
					{slides?.map((slide, i) => (
            <React.Fragment key={slide._key ? slide._key : slide.title}>
							{brandsSlider ? 
								<BrandTile
									content={slide} 
									relatedBrand
									css={css`
										flex: 0 0 calc(20% - 40px);
										margin-right: 40px;
										box-sizing: border-box;
										/* pointer-events: ${this.state.isDragging ? 'none' : 'all'}; */
										${mobile}{
											flex: 0 0 35%;
											margin-right: 15px;
										}
									`}
								/>
							:
								<ProductTile
									content={slide} 
									css={css`
										flex: 0 0 calc(25% - 30px);
										margin-right: 40px;
										box-sizing: border-box;
										/* pointer-events: ${this.state.isDragging ? 'none' : 'all'}; */
										${mobile}{
											flex: 0 0 50%;
											margin-right: 15px;
										}
									`}
								/>
							}
            </React.Fragment>
          ))}
					{children}
					</SliderContainer>
				</SliderRef>
				<SliderButton 
          onClick={() => nextSlide()}
          css={css`justify-self: start; display: ${slides?.length <= 4 ? `none` : `block`};`}
        >
          <Rarr/>
        </SliderButton>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	overflow: hidden;
`
const SliderRef = styled.div`
	grid-column: 2/12;
	overflow: hidden;
	${mobile}{
		grid-column: span 12;
	}
`
const SliderContainer = styled.div`
	display: flex;
	height: 100%;
`
const Header = styled.div`
  display: flex; 
  grid-column: span 12;
  justify-content: center;
  margin-bottom: 55px;
  text-align: center;
  ${mobile}{
    margin-bottom: 34px;
  }
`
const MobileHeader = styled.div`
  display: none;
  ${mobile}{
    display: grid; 
    grid-column: span 12;
    grid-template-columns: max-content 1fr;
    align-content: center;
    margin-bottom: 24px;
  }
`
const FeatureHeading = styled.div`
  display: flex;
  justify-content: center;
  justify-self:start;
`
const CrushHeart = styled(Heart)`
  width: 23px;
  height: 30px;
  margin-left: 12px;
`
const Arrows = styled.div`
  justify-self: end;
`
const SliderButton = styled.button`
 &>svg{
   height: 10px
 }
 ${mobile}{
   display: none;
 }
`

CenteredSliderEmbla.propTypes = {
	className: PropTypes.string
}

export default CenteredSliderEmbla