import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Rarr } from '~components/Svg'
import Image from '~components/Image'
import SanityLink from '~components/SanityLink'

const FeaturedTile = ({ className, content, aspectRatio }) => {
	const [hovered, setHovered] = useState(false)

	return(
		<Wrap 
			link={content.link} 
			className={className} 
			onMouseEnter={() => setHovered(true)} 
			onMouseLeave={() => setHovered(false)}
		>
			<Overlay/>
			<FlexImage 
				image={content.image}
				aspectRatio={aspectRatio}
				css={css`transform: ${hovered ? `scale(1.02)` : `scale(1)`};`}
			/>
			<Text css={css`
				transform: ${hovered ? `translateY(-7px)` : `translateY(0px)`};
			`}>
			<ColHeading className='caps'>{content.subHeading}</ColHeading>
			<h6 css={css``}>{content.heading}</h6>
			<Rarr css={css`height: 10px;${mobile}{height: 7px;}`}/>
			</Text>
		</Wrap>
	 )
}

const Wrap = styled(SanityLink)`
	position: relative; 
	overflow: hidden;
`
const FlexImage = styled(Image)`
	transition: transform 0.3s;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,1,1,0) 100%);
  z-index: 1;
`
const Text = styled.div`
	transition: transform 0.3s;
  position: absolute;
  left: 0;
  bottom: 0;
  color: var(--white);
  padding: 22px 27px;
  z-index: 2;
  ${mobile}{
    padding: 16px 17px;
  }
`
const ColHeading = styled.h6`
  margin-bottom: 6px;
  font-weight: 600;
`

FeaturedTile.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	featuredBrand: PropTypes.bool,
}

export default FeaturedTile