import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'

const CategoryTile = ({ className, category, small }) => {
	return (
		<Wrap className={className} to={resolveLink(category?.collection)} small={small}>
			<Image image={category?.image}/>
			<Title className='h4' small={small}>{category?.collection?.title}</Title>
		</Wrap>
	)
}

const Wrap = styled(Link)`
	padding-right: ${props => props.small ? `20px` : `40px`};
	box-sizing: border-box;
	&:last-of-type{
		padding-right: 0;
	}
	${mobile}{
		padding-right: 0;
	}
`
const Title = styled.div`
	text-align: center;
	margin-top: 10px;
	padding-bottom: 9px;
	border-bottom: 1px solid var(--black);
	font-size: ${props => props.small ? `15px` : `inherit`};
	line-height: ${props => props.small ? `16px` : `inherit`};
	${tablet}{
		font-size: ${props => props.small ? `10px` : `inherit`};
		line-height: ${props => props.small ? `12px` : `inherit`};
	}
	${mobile}{
		margin-top: 7px;
		padding-bottom: 6px;
	}
`

CategoryTile.propTypes = {
	className: PropTypes.string
}

export default CategoryTile